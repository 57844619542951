import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className="container mx-auto my-auto flex justify-center items-center h-screen pb-32">
        <TailSpin
            visible={true}
            height="60"
            width="60"
            color="#eab308"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            />
    </div>
  );
};

export default Loader;