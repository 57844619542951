import React, { useEffect, useState } from 'react';
import { fetchRecords } from '../services/airtableService';
import Loader from './Loader';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getServices = async () => {
      const records = await fetchRecords('Services');
      setServices(records);
      setLoading(false);
    };
    getServices();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="container mx-auto px-3 py-6 sm:p-6 sm:mt-24 mt-16">
      {loading ? 
        <Loader/> 
      :
      <div>
        <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Our Services</h1>
        <span className="border-b-2 border-yellow-500 w-36 block mt-2 mb-6"></span>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services && services.map((service) => (
            <div className="py-6 px-3 sm:p-6 text-center bg-white rounded-xl shadow-lg" key={service.id}>
              <img className="inline-block h-28 w-28 sm:h-32 sm:w-32 object-cover" src={service.fields.Logo[0].url} alt=""></img>
              <h3 className="text-xl sm:text-2xl font-bold text-slate-800 mt-3">{service.fields.Name}</h3>
              <span className="border-b-2 border-yellow-500 w-1/2 block my-3 mx-auto"></span>
              <p className="text-slate-600 font-medium text-sm sm:text-base">{service.fields.Description}</p>
            </div>
          ))}
        </div>
      </div> 
      }
    </div>
  );
};

export default Services;