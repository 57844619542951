import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="bg-white shadow p-3 sm:py-4 sm:px-6 fixed top-0 left-0 right-0 w-100 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <div className="container flex items-center">
              <img src={Logo} alt="Four Star Marble Logo" className="w-10 sm:w-16"/>
          </div>
          <div className="container mx-auto hidden justify-between sm:flex">
            <NavLink to="/" className={({ isActive }) => isActive ? "font-semibold text-slate-800 border-b-2 border-yellow-500" : "font-semibold text-slate-600 hover:text-slate-800 hover:border-b-2 hover:border-yellow-500"}>Home</NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "font-semibold text-slate-800 border-b-2 border-yellow-500" : "font-semibold text-slate-600 hover:text-slate-800 hover:border-b-2 hover:border-yellow-500"}>About</NavLink>
            <NavLink to="/products" className={({ isActive }) => isActive ? "font-semibold text-slate-800 border-b-2 border-yellow-500" : "font-semibold text-slate-600 hover:text-slate-800 hover:border-b-2 hover:border-yellow-500"}>Products</NavLink>
            <NavLink to="/services" className={({ isActive }) => isActive ? "font-semibold text-slate-800 border-b-2 border-yellow-500" : "font-semibold text-slate-600 hover:text-slate-800 hover:border-b-2 hover:border-yellow-500"}>Services</NavLink>
            <NavLink to="/contact" className={({ isActive }) => isActive ? "font-semibold text-slate-800 border-b-2 border-yellow-500" : "font-semibold text-slate-600 hover:text-slate-800 hover:border-b-2 hover:border-yellow-500"}>Contact</NavLink>
          </div>
          <div className="container flex justify-end items-center sm:hidden text-slate-600">
            <FontAwesomeIcon icon={faBars} size="2xl" onClick={() => setShowMobileNav(!showMobileNav)}/>
          </div>
        </div>
        {showMobileNav && (
          <div className="flex flex-column items-center justify-start mt-3 border-t border-slate-300">
            <ul className="list-none pt-2 w-full">
              <li onClick={() => { navigate('/'); setShowMobileNav(false); }} className="py-2 font-semibold text-sm text-slate-600">Home</li>
              <li onClick={() => { navigate('/about'); setShowMobileNav(false); }} className="py-2 font-semibold text-sm text-slate-600">About</li>
              <li onClick={() => { navigate('/products'); setShowMobileNav(false); }} className="py-2 font-semibold text-sm text-slate-600">Products</li>
              <li onClick={() => { navigate('/services'); setShowMobileNav(false); }} className="py-2 font-semibold text-sm text-slate-600">Services</li>
              <li onClick={() => { navigate('/contact'); setShowMobileNav(false); }} className="py-2 font-semibold text-sm text-slate-600">Contact</li>
            </ul>
          </div>
        )}
    </nav>
  );
};

export default Navigation;