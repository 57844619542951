import React, { useEffect, useState } from 'react';
import { fetchRecords } from '../services/airtableService';
import Background from '../assets/images/background.jpg';
import Loader from './Loader';

const Home = () => {
  const [companyInfo, setCompanyInfo] = useState([]);
  const [coreFacts, setCoreFacts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getCompanyInfo = async () => {
      const records = await fetchRecords('CompanyInformation');
      setCompanyInfo(records[0].fields);
      setLoading(false);
    };
    getCompanyInfo();
  }, []);

  useEffect(() => {
    const getCoreFacts = async () => {
      const records = await fetchRecords('CompanyCoreFacts');
      setCoreFacts(records);
    };
    getCoreFacts();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="container mx-auto sm:mt-24 mt-16">
    {loading ? 
      <Loader/> 
      :
      <div>
        <section className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${Background})` }}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="p-3 relative z-10 flex flex-col items-center justify-center h-full text-center text-slate-200">
            <h1 className="text-5xl sm:text-6xl font-bold text-yellow-500">Welcome to Four Star Marble</h1>
            <h2 className="text-2xl sm:text-3xl font-semibold mt-4">{companyInfo.Tagline}</h2>
            <p className="mt-6 max-w-2xl tracking-wide font-medium">{companyInfo.Specialization}</p>
          </div>
        </section>

        <section className="sm:my-10 sm:p-6 p-3 my-3">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {coreFacts && coreFacts.map((coreFact) => (
              <div className="py-8 px-3 sm:py-16 sm:px-6 bg-white rounded-xl shadow-md text-center" key={coreFact.id}>
                <h3 className="text-2xl font-bold text-slate-800">{coreFact.fields.Title}</h3>
                <span className="border-b-2 border-yellow-500 w-1/2 block my-3 mx-auto"></span>
                <p className="text-slate-600 font-medium">{coreFact.fields.Description}</p>
              </div>
            ))}
          </div>
        </section>
      </div> 
    }
    </div>
  );
};

export default Home;