import React, { useEffect, useState } from 'react';
import { fetchRecords } from '../services/airtableService';
import Loader from './Loader';

const About = () => {
  const [companyInfo, setCompanyInfo] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getCompanyInfo = async () => {
      const records = await fetchRecords('CompanyInformation');
      setCompanyInfo(records[0].fields);
      setLoading(false);
    };
    getCompanyInfo();
  }, []);

  useEffect(() => {
    const getPartnerInfo = async () => {
      const records = await fetchRecords('Partners');
      setPartnerInfo(records);
    };
    getPartnerInfo();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="container mx-auto px-3 py-6 sm:p-6 sm:mt-24 mt-16">
     {loading ? 
      <Loader/> 
      :
      <div>
        <section className="mb-5 sm:mb-10">
          <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Our Inspiration</h1>
          <span className="border-b-2 border-yellow-500 w-24 block mt-2 mb-3"></span>
          <p className="text-base sm:text-lg text-slate-600 font-semibold">{companyInfo.Inspiration}</p>
        </section>

        <section className="mb-5 sm:mb-10">
          <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Our Story</h1>
          <span className="border-b-2 border-yellow-500 w-24 block mt-2 mb-3"></span>
          <p className="text-base sm:text-lg text-slate-600 font-semibold">{companyInfo.Story}</p>
        </section>

        <section>
        <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Our Partners</h1>
        <span className="border-b-2 border-yellow-500 w-36 block mt-3 sm:mb-6 mb-3"></span>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {partnerInfo && partnerInfo.map((partner) => (
              <div className="py-6 px-3 sm:p-6 bg-white rounded-xl shadow-lg text-center" key={partner.id}>
                <img className="inline-block h-40 w-40 sm:h-48 sm:w-48 rounded-full ring-2 ring-slate-100 object-cover" src={partner.fields.Photo[0].url} alt=""></img>
                <h3 className="text-xl sm:text-2xl font-bold text-slate-800 mt-3">{partner.fields.Name}</h3>
                <span className="border-b-2 border-yellow-500 w-1/2 block my-3 mx-auto"></span>
                <p className="text-slate-600 font-semibold text-base sm:text-lg mb-1">{partner.fields.Role} · {partner.fields.Country}</p>
                <p className="text-slate-600 font-medium text-sm sm:text-base">{partner.fields.Bio}</p>
              </div>
            ))}
          </div>
        </section>
      </div> 
      }
    </div>
  );
};

export default About;