import React, { useEffect, useState } from 'react';
import { fetchRecords } from '../services/airtableService';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    const getCompanyInfo = async () => {
      const records = await fetchRecords('CompanyInformation');
      setCompanyInfo(records[0].fields);
    };
    getCompanyInfo();
  }, []);

  return (
    <footer className="bg-slate-800 text-slate-200 p-3 sm:p-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center mt-6 border-b border-slate-700 pb-6">
        <div className="flex justify-center mb-4 md:mb-0">
          <ul className="list-none flex flex-row">
            <li onClick={() => navigate('/')} className="mx-2 text-slate-200 hover:text-yellow-500 cursor-pointer">Home</li>
            <li onClick={() => navigate('/about')} className="mx-2 text-slate-200 hover:text-yellow-500 cursor-pointer">About</li>
            <li onClick={() => navigate('/products')} className="mx-2 text-slate-200 hover:text-yellow-500 cursor-pointer">Products</li>
            <li onClick={() => navigate('/services')} className="mx-2 text-slate-200 hover:text-yellow-500 cursor-pointer">Services</li>
            <li onClick={() => navigate('/contact')} className="mx-2 text-slate-200 hover:text-yellow-500 cursor-pointer">Contact</li>
          </ul>
        </div>
        <div className="flex justify-center mb-4 md:mb-0">
          <a href={companyInfo.FacebookURL} className="mx-2 text-slate-200 hover:text-yellow-500" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="lg"/>
          </a>
          <a href={companyInfo.TwitterURL} className="mx-2 text-slate-200 hover:text-yellow-500" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="lg"/>
          </a>
          <a href={companyInfo.InstagramURL} className="mx-2 text-slate-200 hover:text-yellow-500" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="lg"/>
          </a>
          <a href={companyInfo.LinkedInURL} className="mx-2 text-slate-200 hover:text-yellow-500" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="lg"/>
          </a>
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center my-6">
        <div className="text-center md:text-left mb-4 md:mb-0 text-sm">
          <p>&copy; {format(new Date(), 'yyyy')} {companyInfo.CompanyName}. All rights reserved.</p>
          <p>{companyInfo.Address}</p>
        </div>
        <div className="text-center md:text-right text-sm">
          <p>{companyInfo.ContactPhone}</p>
          <p>{companyInfo.ContactEmail}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;