import axios from 'axios';

const TOKEN = process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN;
const BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;

const airtableApi = axios.create({
  baseURL: `https://api.airtable.com/v0/${BASE_ID}`,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
});

export const fetchRecords = async (table) => {
  const response = await airtableApi.get(`/${table}`);
  return response.data.records;
};

export const addInquiry = async (inquiry) => {
  const response = await airtableApi.post('/Inquiries', {
    fields: inquiry,
  });
  return response.data;
};