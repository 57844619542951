import React, { useEffect, useState } from 'react';
import { fetchRecords } from '../services/airtableService';
import Loader from './Loader';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getProducts = async () => {
      const records = await fetchRecords('Products');
      setProducts(records);
      setLoading(false);
    };
    getProducts();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="container mx-auto px-3 py-6 sm:p-6 sm:mt-24 mt-16">
     {loading ? 
      <Loader/> 
      :
      <div>
        <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Our Products</h1>
        <span className="border-b-2 border-yellow-500 w-36 block mt-2 mb-6"></span>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {products && products.map((product) => (
            <div className="bg-white rounded-xl shadow-lg" key={product.id}>
              <img className="inline-block h-60 sm:h-72 w-full object-cover rounded-t-xl" src={product.fields.Image[0].url} alt=""></img>
              <div className="px-3 pb-6 sm:p-6 pt-0 text-center">
                <h3 className="text-xl sm:text-2xl font-bold text-slate-800 mt-3">{product.fields.Name}</h3>
                <span className="border-b-2 border-yellow-500 w-1/2 block my-3 mx-auto"></span>
                <p className="text-slate-600 font-semibold text-base sm:text-lg mb-1">{product.fields.Category} · {product.fields.Origin}</p>
                <p className="text-slate-600 font-medium text-sm sm:text-base">{product.fields.Description}</p>
              </div>
            </div>
          ))}
        </div>
      </div> 
      }
    </div>
  );
};

export default Products;