import React, { useState, useEffect } from 'react';
import { addInquiry } from '../services/airtableService';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [submittedForm, setSubmittedForm] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const inquiryData = {
      Name: formData.name,
      Email: formData.email,
      Phone: formData.phone,
      Message: formData.message
    };

    // Add inquiry to Airtable
    try {
      await addInquiry(inquiryData);
    } catch (error) {
      console.error('Failed to add inquiry to Airtable:', error);
    }

    // Send email notification via EmailJS
    const emailParams = {
      from_name: formData.name,
      from_email: formData.email,
      from_phone: formData.phone,
      message: formData.message,
      to_email: 'sabaamir.m@gmail.com' // Replace with the company email address
    };

    emailjs.send('service_2vbw5hi', 'template_616y6og', emailParams, {
      publicKey: 'i-Boe9x6FSIJ7PRyC',
    })
      .then((response) => {
        // console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      });

    // Reset form
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
    });

    setSubmittedForm(true);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="container mx-auto px-3 py-6 sm:p-6 sm:mt-24 mt-16">
      <h1 className="text-2xl sm:text-3xl font-bold text-slate-800">Contact Us</h1>
      <span className="border-b-2 border-yellow-500 w-28 block mt-2 mb-3"></span>
      {submittedForm ? (
        <div className="mt-3 mb-16 sm:my-6 min-h-80">
          <h1 className="text-xl sm:text-2xl font-bold text-slate-800">Thank You for Your Inquiry!</h1>
          <p className="text-slate-600 font-semibold text-base sm:text-lg mt-1">Your message has been successfully sent. We appreciate you reaching out to us. One of our representatives will get back to you shortly.</p>
        </div>
      ) : (
        <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-slate-800 text-base sm:text-lg font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="border-2 border-slate-200 appearance-none border rounded-lg w-full py-2 px-3 text-slate-800 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-slate-800 text-base sm:text-lg font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="border-2 border-slate-200 appearance-none border rounded-lg w-full py-2 px-3 text-slate-800 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-slate-800 text-base sm:text-lg font-bold mb-2" htmlFor="phone">
              Phone Number
            </label>
            <input
              className="border-2 border-slate-200 appearance-none border rounded-lg w-full py-2 px-3 text-slate-800 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your phone number"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-slate-800 text-base sm:text-lg font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="border-2 border-slate-200 appearance-none border rounded-lg w-full py-2 px-3 text-slate-800 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your message"
              required
            ></textarea>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-yellow-500 hover:shadow-md hover:text-slate-900 text-slate-800 text-base sm:text-lg font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline w-full text-center"
              type="submit"
            >
              Send
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;